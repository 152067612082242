/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

export default {
  getPageNotFound: () => '/page-not-found',
  inDevelopment: () => '/in-development',

  getBase: () => '/',

  support: () => '/support',

  auth: {
    signIn: () => '/sign-in',
    signUp: () => '/sign-up',
    logout: () => '/logout',
    secretSignUp: () => '/Ung9phah/sign-up',
    signUpSuccess: () => '/sign-up/success',

    forgotPassword: () => '/forgot-password',
    recoverPassword: () => '/recover-password',
    signInIntegration: () => '/oauth',
  },

  contracts: {
    base: () => '/contracts',
    create: () => '/create',
    createInProject: (projectID = ':projectId') =>
      `/projects/${projectID}/contracts/create`,
    contract: ({ contractId = ':contractId' } = {}) => `/${contractId}`,
    edit: ({ contractId = ':contractId' } = {}) => `/${contractId}/edit`,
    incoming: ({ contractId = ':contractId' } = {}) =>
      `/${contractId}/invoices`,
    journal: {
      base: ({ contractId = ':contractId', section = ':section?' } = {}) =>
        `/${contractId}/works/${section}`,
      new: ({ projectId = ':projectId', contractId = ':contractId' } = {}) =>
        `/projects/${projectId}/contracts/${contractId}/works/new-record`,
    },
    schedule: ({ contractId = ':contractId' } = {}) =>
      `/${contractId}/schedule`,
  },

  contract: {
    base: ({ projectId = ':projectId', contractId = ':contractId' } = {}) =>
      `/projects/${projectId}/contracts/${contractId}`,
    create: ({ projectId = ':projectId' } = {}) =>
      `/projects/${projectId}/contracts/create`,
    tab: ({ projectId = ':projectId', contractId = ':contractId' } = {}) =>
      `/projects/${projectId}/contracts/${contractId}/:tab`,
  },

  projects: {
    base: () => '/projects',
    create: () => '/projects/create',
    project: (projectID = ':projectId') => `/projects/${projectID}`,
    tab: (projectID = ':projectId') => `/projects/${projectID}/:tab`,
    edit: (projectID = ':projectId') => `/projects/${projectID}/edit`,
    registry_of_acts: (projectID = ':projectId') =>
      `/projects/${projectID}/registry-of-acts`,
    works: (projectID = ':projectId') => `/projects/${projectID}/works`,
    sakura: (projectID = ':projectId') => `/projects/${projectID}/sakura`,
  },

  zhor: {
    base: ({ projectId = ':projectId', contractId = ':contractId' } = {}) =>
      `/projects/${projectId}/contracts/${contractId}/journals`,
    tab: ({ projectId = ':projectId', contractId = ':contractId?' } = {}) =>
      `/projects/${projectId}/contracts/${contractId}/journals/:tab`,
  },

  account: {
    base: () => '/my-account',
    edit: () => '/my-account/edit',
  },

  organization: {
    base: () => '/organization',
    tab: () => '/organization/:tab',
    create: () => '/organization/create',
    edit: () => '/organization/edit',
  },

  events: {
    base: () => '/events',
  },

  integrations: {
    base: () => '/integrations',
  },

  navigator: {
    base: () => '/sakura',
    project: (projectID = ':projectId') => `/projects/${projectID}/requisites`,
    contract: (projectId = ':projectId', journalId = ':journalId') =>
      `/sakura/project/${projectId}/contract/${journalId}`,
  },
}
