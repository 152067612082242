/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { useState, useEffect } from 'react'

import { requiredText } from '@builddocs/data-management'
import { WorkType } from '@builddocs/data-management'
import { useDisclosure } from '@builddocs/system'
import TextareaAutosize from '@mui/base/TextareaAutosize'
import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  Button,
  Stack,
  Typography,
  DialogContent,
  FormControl,
  FormLabel,
  TextField,
  FormHelperText,
  DialogActions,
  DialogTitle,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

interface IDeviationFields {
  information: string
  problemDescription: string
  deadline: string
  externalEntityInfo: string
}

interface IDeviationDialogProps {
  title?: string
  subtitle?: string
  onSubmit: (
    body: IDeviationFields,
    additionalInfo: {
      id: string
      workType?: WorkType
      aosrIds?: string[]
      secondaryActIds?: string[]
    },
    onSuccess: () => void,
  ) => void
  additionalInfo: {
    id: string
    workType?: WorkType
    aosrIds?: string[]
    secondaryActIds?: string[]
  }
  initialInformation?: string
  complect?: string
  countActs?: number
  documentType: string
  isDisabled?: boolean
}

export const DeviationDialog = (props: IDeviationDialogProps): JSX.Element => {
  const {
    title,
    subtitle,
    onSubmit,
    additionalInfo,
    complect,
    countActs,
    documentType,
    isDisabled,
  } = props
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { handleSubmit, formState, errors, control } =
    useForm<IDeviationFields>()

  const submitDeviation: SubmitHandler<IDeviationFields> = data => {
    if (formState.isSubmitting || isLoading) return

    setIsLoading(true)

    onSubmit(
      { ...data, externalEntityInfo: documentType },
      additionalInfo,
      () => {
        setIsLoading(false)
        onClose()
      },
    )
  }

  useEffect(() => {
    if (isOpen) setIsLoading(false)
  }, [isOpen])

  return (
    <>
      {complect === 'inspect' ? (
        <Button
          key={1}
          color="rejected"
          disabled={!countActs || isDisabled}
          id="deviation-dialog-button-open-modal"
          size="small"
          onClick={onOpen}
        >
          {countActs
            ? `Отклонить акты (выбрано: ${countActs})`
            : `Выберите акты для отклонения`}
        </Button>
      ) : (
        <Button
          color="rejected"
          disabled={isDisabled}
          id="deviation-dialog-button-open-modal-alter"
          size="small"
          onClick={onOpen}
        >
          Отклонить
        </Button>
      )}
      <Dialog fullWidth maxWidth="xs" open={isOpen} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>

        <DialogContent>
          <form id="deviation-dialog">
            {subtitle && <Typography mb={4}>{subtitle}</Typography>}
            <Stack spacing={2}>
              <Controller
                control={control}
                defaultValue=""
                name="problemDescription"
                render={props => (
                  <FormControl
                    fullWidth
                    error={!!errors?.problemDescription}
                    id="place"
                  >
                    <FormLabel>Выявленные недостатки</FormLabel>
                    <TextareaAutosize
                      minRows={2}
                      style={{
                        border: `1px solid ${
                          errors.problemDescription ? 'red' : 'lightgray'
                        }`,
                        borderRadius: 1.25,
                      }}
                      {...props}
                    />
                    <FormHelperText>
                      {errors?.problemDescription?.message}
                    </FormHelperText>
                  </FormControl>
                )}
                rules={{ required: requiredText }}
              />

              <Controller
                control={control}
                defaultValue={null}
                name="deadline"
                render={props => (
                  <DatePicker
                    {...props}
                    clearable
                    label="Срок устранения"
                    mask="__.__.____"
                    minDate={new Date()}
                    renderInput={({ error, ...params }) => (
                      <TextField
                        error={!!errors.deadline}
                        helperText={errors.deadline?.message}
                        {...params}
                      />
                    )}
                  />
                )}
              />
            </Stack>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            aria-label="close"
            disabled={formState.isSubmitting || isLoading}
            onClick={onClose}
          >
            Отмена
          </Button>
          <LoadingButton
            loading={formState.isSubmitting || isLoading}
            variant="contained"
            onClick={handleSubmit(submitDeviation)}
          >
            Сохранить
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
