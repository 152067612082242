import { FolderTree, ArchiveFile, Folder } from './archive.types'

export type GetFolderStructureRequest = {
  projectId: string
}

export type GetFolderStructureResponse = {
  projectId: string
  hierarchy: FolderTree[]
}

export type GetFolderDocumentsRequest = {
  projectId: string
  folderId: string

  size?: number
  page?: number
  search?: string
  sort?: string
  direction?: string
}

export type GetFolderDocumentsResponse = {
  pageCount: number
  pageNumber: number
  pageSize: number
  projectId: string
  parentId: string
  totalPages: number
  totalElements: number
  content: ArchiveFile[]
}

export type GetFileContentsRequest = {
  projectId: string
  folderId: string
  fileId: string
}

export type GetFileContentsResponse = {
  file: Blob
}

export type DeleteFolderRequest = {
  projectId: string
  folderId: string
}

export type DeleteFileRequest = {
  projectId: string
  folderId: string
  fileId: string
}

export type CreateFolderRequest = {
  parentFolerId: string
  name: string
}

export type CreateFolderResponse = {
  parentFolerId: string
  name: string
}

export type DeleteFolderResponse = void
export type DeleteFileResponse = void

export type UpdateFolderResponse = Pick<Folder, 'name' | 'isFixed'>
export type UpdateFileResponse = Pick<
  ArchiveFile,
  'name' | 'number' | 'createdAt' | 'issuedBy' | 'issuedAt'
>
