// https://wiki.yandex.com/homepage/builddocs/proektirovanie/builddocs-1.0.-tekushhaja/integracii-s-klientami/pik/arxiv-id/vnutrennee-rest-api-arxiv/

export type Folder = {
  id: string
  name: string
  isFixed: boolean
  createdAt: string
  createdBy: string
}

export type FolderTree = Folder & { children: FolderTree[] }

export type ArchiveFile = {
  metadata: {
    externalId: string | null
    id: string
    isBadFileSize: boolean
    isBadFileSizeUpdatedAt: string | null
    isBadFormat: boolean
    isBadFormatUpdatedAt: string | null
    isDeleted: boolean | null
    isUploaded: boolean
    name: string
    s3Key: string
    uploadedAt: string
  }
  id: string
  fileId: string
  projectId: string
  folderId: string
  name: string
  number: number | null
  description: string | null
  issuedBy: string | null
  issuedAt: string | null
  createdAt: string
  createdBy: string
  isFixed: boolean
  isDeleted: boolean
  deletedAt: string | null
  deletedBy: string | null
}
