/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2020 ООО АДАПТ info@acfs.spb.ru
 *
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { RestAPI } from '../RestAPI'

import { ProjectRequest } from './project.types'

import type { UUID } from '../../models'

export const createProject = (data: ProjectRequest) =>
  RestAPI.post('/projects', data)

export const updateProject = (data: any) =>
  RestAPI.put('/projects', data).then(res => res.data || {})

interface GetProjects {
  pageNumber: number
  pageSize?: number
  searchValue?: string
}

export const getListProjects = ({
  pageNumber,
  pageSize,
  searchValue,
}: GetProjects) =>
  RestAPI.get(`/projects/`, {
    params: {
      pageNumber,
      pageSize,
      searchValue,
    },
  }).then(
    res =>
      res.data || {
        pageCount: 0,
        pageNumber: 0,
        pageSize: 0,
        entityList: [],
      },
  )

export const getOneProject = (projectId: UUID) =>
  RestAPI.get(`/projects/project/${projectId}`).then(res => res.data || {})

export const getStatistic = (projectId: UUID) =>
  RestAPI.get(`/statistic/${projectId}`).then(res => res.data || {})
