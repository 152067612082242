/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

export const FILE_STORE_BASE =
  process.env.NODE_ENV === 'development' || process.env.NX_ENV === 'development'
    ? `https://files-dev.builddocs.online/filestore`
    : `https://files.builddocs.online/filestore`
export const FILE_STORE_API = `${FILE_STORE_BASE}/api/v1`

export const ADAPT_BASE =
  process.env.NODE_ENV === 'development' || process.env.NX_ENV === 'development'
    ? `https://dev.builddocs.online`
    : `https://builddocs.online`

export const ADAPT_API = `${ADAPT_BASE}/api`
