/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2022 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import produce from 'immer'
import { useQuery, useQueryClient } from 'react-query'

import { getListProjects } from './projects.service'

import type { Project } from './project.types'

interface ProjectProps {
  pageNumber?: number
  pageSize?: number
  searchValue?: string
}

interface IProjectsHook {
  isLoading: boolean
  isFetching: boolean
  refreshProjects: () => void
  updateProjectsLocal: (nextData: Project, index: number) => void
  projects: Project[]
  error?: unknown
  isError?: boolean
  pageNumber: number
  pageSize: number
  pageCount: number
}

export const useProjects: (props: ProjectProps) => IProjectsHook = props => {
  const { pageNumber = 0, pageSize = 1000, searchValue = '' } = props

  const queryClient = useQueryClient()

  const queryKey = ['projects', props]
  const { data, isLoading, isFetching, isError, error } = useQuery<{
    pageCount: number
    pageNumber: number
    pageSize: number
    entityList: Project[]
  }>(
    queryKey,
    async () =>
      await getListProjects({
        pageNumber,
        pageSize,
        searchValue,
      }),
    { cacheTime: 300000, staleTime: 5000 },
  )

  const projects = data?.entityList

  const refreshProjects = () => queryClient.invalidateQueries(queryKey)

  const updateProjectsLocal = (nextData, index: number) => {
    if (!data?.entityList) return

    queryClient.setQueryData(
      queryKey,
      produce(data, draft => {
        draft.entityList[index] = nextData
      }),
    )
  }

  return {
    projects: projects ?? [],
    pageNumber: data?.pageNumber ?? 0,
    pageSize: data?.pageSize ?? 0,
    pageCount: data?.pageCount ?? 0,
    isLoading,
    isFetching,
    error,
    isError,
    refreshProjects,
    updateProjectsLocal,
  }
}
