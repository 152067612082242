/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

import { User } from '../../models'

interface NameBase {
  firstName: User['firstName']
  middleName?: User['middleName']
  lastName: User['lastName']
}

function concatenateNames(str: string[]): string {
  return str.filter(Boolean).join(' ')
}

export function getFullName<T extends NameBase>(user: T): string {
  if (!user) return ''

  const { firstName = '', middleName = '', lastName = '' } = user

  return concatenateNames([firstName, middleName, lastName])
}

export function getFullNameReversed<T extends NameBase>(user: T): string {
  if (!user) return ''

  const { firstName = '', middleName = '', lastName = '' } = user

  return concatenateNames([lastName, firstName, middleName])
}

export function getShortName<T extends NameBase>(user: T): string {
  if (!user) return ''

  const { firstName = '', lastName = '' } = user

  return concatenateNames([firstName, lastName])
}

export function getShortNameReversed<T extends NameBase>(user: T): string {
  if (!user) return ''

  const { firstName = '', lastName = '' } = user

  return concatenateNames([lastName, firstName])
}

export function getNameWithInitialsReversed<T extends NameBase>(
  user: T,
): string {
  if (!user) return ''

  const { firstName = '', middleName = '', lastName = '' } = user

  const getFormattedInitial = (str: string): string =>
    str ? `${str.charAt(0).toUpperCase()}.` : ''

  const firstNameInitial = getFormattedInitial(firstName)
  const middleNameInitial = getFormattedInitial(middleName)

  return concatenateNames([lastName, firstNameInitial, middleNameInitial])
}
