import { useQuery } from 'react-query'
import {
  GetFolderStructureRequest,
  GetFolderStructureResponse,
  GetFolderDocumentsRequest,
  GetFolderDocumentsResponse,
} from './archive.contract'
import { ArchiveService } from './archive.service'

export function useGetFolderStructure({
  projectId,
}: GetFolderStructureRequest) {
  const keys = ['project', 'archive', projectId]

  const {
    data: folderStructure,
    isLoading,
    isFetching,
    error,
    isError,
  } = useQuery<GetFolderStructureResponse>({
    queryKey: keys,
    queryFn: async () => await ArchiveService.getFolderStructure({ projectId }),
  })

  return {
    folderStructure,
    isLoading,
    isFetching,
    error,
    isError,
  }
}

export function useGetFolderDocuments({
  projectId,
  folderId,
  ...queryParams
}: GetFolderDocumentsRequest) {
  const keys = ['project', 'archive', projectId, folderId, queryParams]

  const {
    data: documents,
    isLoading,
    isFetching,
    error,
    isError,
  } = useQuery<GetFolderDocumentsResponse>({
    queryKey: keys,
    queryFn: async () =>
      await ArchiveService.getFolderDocuments({
        projectId,
        folderId,
        ...queryParams,
      }),
    staleTime: 5000,
    cacheTime: 300000,
    refetchOnWindowFocus: true,
    enabled: !!projectId && !!folderId,
  })

  return {
    documents,
    isLoading,
    isFetching,
    error,
    isError,
  }
}
