import { useContext, useMemo } from 'react'

import {
  AbilityContext,
  routes,
  useContract,
  useCoordinator,
  useCurrentUser,
} from '@builddocs/data-management'
import { Application, LoadableApplicationKeys } from '@builddocs/filestore-api'
import { AnyAbility } from '@casl/ability'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Button, Paper, Popover, Stack, Typography } from '@mui/material'
import {
  bindPopover,
  bindToggle,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import { Location, useLocation, useMatch } from 'react-router-dom'

const tabName = {
  'deviation-journal': 'Журнал замечаний',
  matlink: 'Матлинк',
  requisites: 'Реквизиты',
  docstore: 'Документарий',
  journals: 'Журналы',
  'works-names': 'Наименования работ',
  invoices: 'Материалы',
  works: 'Работы',
  acts: 'Исполнительная документация',
}

const getCurrentRights = (
  tab: string | undefined,
  {
    iAmAdmin,
    canSignContract,
    canInspect,
    canExecute,
    canWriteSchedulers,
    canWriteSection3,
    canWriteInvoices,
    canSignAct,
    canSendActToSigning,
    isMy,
    canEditContract,
  }: {
    canSignInvoices: boolean
    canSignSchedulers: boolean
    canSignContract: boolean
    isMy: boolean
    iAmAdmin: boolean
    canExecute: boolean
    canWriteInvoices: boolean
    canWriteSection4: boolean
    canWriteSection3: boolean
    canWriteSchedulers: boolean
    canSignGeneralJournal: boolean
    canCreateAct: boolean
    canSignAct: boolean
    canInspect: boolean
    canSendActToSigning: boolean
    canEditContract: boolean
  },
  ability: AnyAbility,
  docstoreTab: string,
) => {
  switch (tab) {
    case 'requisites':
      return [
        { name: 'Я администратор', value: iAmAdmin },
        { name: 'Я создатель договора', value: isMy },
        { name: 'Могу редактировать договор', value: canEditContract },
        { name: 'Могу подписывать договор', value: canSignContract },
      ]

    case 'works-names':
      return [
        {
          name: 'Могу редактировать наименования работ',
          value: canWriteSchedulers,
        },
      ]

    case 'works':
      return [
        {
          name: 'Могу редактировать выполненные работы',
          value: canWriteSection3,
        },
      ]

    case 'invoices':
      return [
        {
          name: 'Могу редактировать накладные',
          value: canWriteInvoices,
        },
      ]

    case 'acts':
      return [
        {
          name: 'Могу редактировать акты и закрытия',
          value: canExecute,
        },
        {
          name: 'Могу проверять акты и закрытия',
          value: canInspect,
        },
        {
          name: 'Могу подписывать акты и закрытия',
          value: canSignAct,
        },
        {
          name: 'Могу отправлять на подписание акты и закрытия',
          value: canSendActToSigning,
        },
      ]

    case 'docstore':
      return [
        {
          name: 'Могу загружать документы',
          value: ability.can('add', docstoreTab),
        },
        {
          name: 'Могу редактировать документы, загруженные мной ',
          value: ability.can('add', docstoreTab),
        },
      ]

    // case 'deviation-journal':
    //   return [
    //     {
    //       name: 'Могу просматривать',
    //       value: true,
    //     },
    //   ]

    default:
      return []
  }
}
const checkTab = (
  location: Location,
  initialDocumentType?: Application,
): LoadableApplicationKeys => {
  const search = new URLSearchParams(location.search)

  return (search.get('tab')?.toUpperCase() ??
    initialDocumentType) as LoadableApplicationKeys
}

export const RightsInfo = () => {
  const ability = useContext(AbilityContext)
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'RightsInfo',
  })
  const { user } = useCurrentUser()
  const { contractId } = useCoordinator() as {
    contractId: string
    projectId: string
  }
  const { contract, getRights } = useContract(contractId ?? '')

  const location = useLocation()
  const match = useMatch(routes.contract.tab())

  const tab = match?.params.tab
  const docstoreTab = checkTab(location, Application.PERMIT)

  const currentRights = useMemo(
    () =>
      getCurrentRights(
        tab,
        {
          ...getRights,
          canEditContract:
            !contract?.prorab?.userId ||
            contract?.prorab?.userId === user?.userId,
        },
        ability,
        docstoreTab,
      ).filter(item => item.value === true),
    [tab, getRights, contract, user.userId, ability, docstoreTab],
  )

  return (
    <Stack ml="auto">
      {currentRights.length > 0 && (
        <Button startIcon={<InfoOutlinedIcon />} {...bindToggle(popupState)}>
          Мои полномочия
        </Button>
      )}
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper sx={{ px: 2, width: 300, pt: 2, pb: 3 }}>
          {tab && (
            <Typography gutterBottom sx={{ fontWeight: 'bold' }}>
              {tabName[tab]}
            </Typography>
          )}
          {tab && (
            <Stack>
              {currentRights.map(right => (
                <Typography key={right.name}>{right.name}</Typography>
              ))}
            </Stack>
          )}
        </Paper>
      </Popover>
    </Stack>
  )
}
