import React from 'react'

import { alpha } from '@mui/material'
import Pagination, { PaginationProps } from '@mui/material/Pagination'
import { styled } from '@mui/material/styles'

const StyledPagination = styled(Pagination)(() => ({
  '& .MuiPaginationItem-rounded': {},
  // TODO: высота зависимая от пропса
  '& .MuiPaginationItem-ellipsis': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '32px',
    borderRadius: '4px',
    backgroundColor: alpha('#EAEEF8', 0.5) + '!important',
  },
  '& .MuiPaginationItem-root': {
    backgroundColor: '#EAEEF8',
  },
  '& .Mui-disabled': {
    opacity: '0.5 !important',
    backgroundColor: '#EAEEF8 !important',
  },
}))

const OutlinedPagination = ({ ...rest }: PaginationProps) => {
  return <StyledPagination {...rest} />
}

export default React.memo(OutlinedPagination)
