import React from 'react'

import { alpha } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import { styled } from '@mui/material/styles'
import TextField, { TextFieldProps } from '@mui/material/TextField'

const SearchIcon = () => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="7" cy="7" r="5.5" stroke="#333333" />
    <path
      d="M9.82949 6C9.41765 4.83481 8.30641 4 7.00019 4C5.69397 4 4.58273 4.83481 4.1709 6"
      stroke="#333333"
    />
    <path d="M11 11L14 14" stroke="#333333" />
  </svg>
)

const StyledSearch = styled(TextField)(() => ({
  color: alpha('#00000099', 0.8),
  '.MuiOutlinedInput-notchedOutline': {
    border: '1px solid #BCBFC0',
    borderRadius: 4,
  },
}))

type SearchProps = TextFieldProps

const Search = ({ sx, ...rest }: SearchProps) => {
  const [value, setValue] = React.useState<string | null>()
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) =>
    setValue(e.target.value)

  return (
    <StyledSearch
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      size="small"
      sx={{ ...sx }}
      value={value}
      onChange={onChangeHandler}
      {...rest}
    />
  )
}

export default React.memo(Search)
