import { RestAPI } from '../RestAPI'

import {
  GetFileContentsRequest,
  GetFileContentsResponse,
  GetFolderDocumentsRequest,
  GetFolderDocumentsResponse,
  GetFolderStructureRequest,
  GetFolderStructureResponse,
} from './archive.contract'

export class ArchiveService {
  static getFolderStructure({
    projectId,
  }: GetFolderStructureRequest): Promise<GetFolderStructureResponse> {
    return RestAPI.get<GetFolderStructureResponse>(
      `/archive/${projectId}/folders`,
    ).then(res => res.data)
  }
  static getFolderDocuments({
    projectId,
    folderId,
    ...params
  }: GetFolderDocumentsRequest): Promise<GetFolderDocumentsResponse> {
    return RestAPI.get<GetFolderDocumentsResponse>(
      `/archive/${projectId}/folders/${folderId}/items`,
      {
        params,
      },
    ).then(res => res.data)
  }
  static getFileContents({
    projectId,
    folderId,
    fileId,
  }: GetFileContentsRequest): Promise<GetFileContentsResponse> {
    return RestAPI.get<GetFileContentsResponse>(
      `/archive/${projectId}/folders/${folderId}/files/${fileId}`,
      {
        responseType: 'blob',
      },
    ).then(res => res.data)
  }
  // // TODO: archive crud
  // static createFolder({
  //   projectId,
  //   ...data
  // }: CreateFolderRequest): Promise<CreateFolderResponse> {
  //   return RestAPI.post<CreateFolderResponse>(`/archive/${projectId}/folders`, {
  //     body: data,
  //   }).then(res => res.data)
  // }
  // static createFile({
  //   projectId,
  //   folderId,
  //   ...data
  // }: CreateFileRequest): Promise<CreateFileResponse> {
  //   return RestAPI.post<CreateFileResponse>(
  //     `/archive/${projectId}/folders/${folderId}/items`,
  //     {
  //       body: data,
  //     },
  //   ).then(res => res.data)
  // }
  // static deleteFolder({
  //   projectId,
  //   folderId,
  // }: DeleteFolderRequest): Promise<DeleteFolderResponse> {
  //   return RestAPI.post<DeleteFolderResponse>(
  //     `/archive/${projectId}/folders/${folderId}`,
  //   ).then(res => res.data)
  // }
  // static deleteFile({
  //   projectId,
  //   folderId,
  //   fileId,
  // }: DeleteFileRequest): Promise<DeleteFileResponse> {
  //   return RestAPI.post<DeleteFolderResponse>(
  //     `/archive/${projectId}/folders/${folderId}/items/${fileId}`,
  //   ).then(res => res.data)
  // }
  //
  // static updateFolder({
  //   projectId,
  //   folderId,
  //   ...data
  // }: UpdateFolderRequest): Promise<UpdateFolderResponse> {
  //   return RestAPI.patch<UpdateFolderResponse>(
  //     `/archive/${projectId}/folders/${folderId}`,
  //     {
  //       body: data,
  //     },
  //   ).then(res => res.data)
  // }
  //
  // static updateFile({
  //   projectId,
  //   folderId,
  //   ...data
  // }: UpdateFileRequest): Promise<UpdateFileResponse> {
  //   return RestAPI.patch<UpdateFileResponse>(
  //     `/archive/${projectId}/folders/${folderId}`,
  //     {
  //       body: data,
  //     },
  //   ).then(res => res.data)
  // }
}
