import { ModalContent, Modal, ModalHeader, ModalBody, ModalFooter, Button } from '@builddocs/mui'
import { useState } from 'react'
import { TextField, Typography } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import { AOSRType, SecondaryActType } from '@builddocs/filestore-api'
import { Controller, useForm } from 'react-hook-form'
import { DatePicker } from '@mui/x-date-pickers'
import formatISO from 'date-fns/formatISO'
import { useToast } from '@builddocs/system'
import { changeAosrDate } from '@builddocs/data-management'

interface IFieldsForm {
  newAosrDate: string
}

export const ChangeAosrDate = ({ act, refreshAct }: { act?: AOSRType | SecondaryActType, refreshAct:()=>void }) => {
  const { errors, handleSubmit, control, watch } =
    useForm<IFieldsForm>()
  const newAosrDate = watch('newAosrDate')
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const { toast } = useToast()
  const handleModalOpen = () => {
    setModalOpen(true)
  }
  const handleModalClose = () => {
    setModalOpen(false)
  }

  const handleSend = async () => {
    if (!act) return
    const transformedDate = formatISO(new Date(newAosrDate), { representation: 'date', })
    if (!transformedDate || transformedDate.length !== 10) return toast.error('Некорректная дата')
    setIsUpdating(true)
    await changeAosrDate( {actDate:transformedDate, aosrId:act?.id, journalId:act?.journalId, versionRemarkExon:act?.versionRemarkExon})
      .then(()=> {
        toast.success('Данные успешно обновлены')
        // refreshAct()
        handleModalClose()
      })
      .catch((e)=>{
        toast.error(`Произошла ошибка во время обновления даты. ${e?.response?.data?.message}`)
      })
      .finally(()=>setIsUpdating(false))
  }

  return (
    <>
      <MenuItem onClick={handleModalOpen}>
        Измененить дату АОСР
      </MenuItem>
      <form>
        {/* баблинг приводит к активации кнопок меню по hotkey */}
        <Modal open={modalOpen} onKeyDown={e => e.stopPropagation()} onClose={handleModalClose}>
          <ModalContent>
            <ModalHeader onClose={handleModalClose}>
              <Typography sx={{ fontSize: 20, fontWeight: 500, color: 'rgba(0,0,0,0.87)' }}>
                Изменение даты АОСР
              </Typography>
            </ModalHeader>
            <ModalBody sx={{display:'flex', flexDirection:'column', gap:'16px'}}>
              <DatePicker
                readOnly
                label="Текущая дата акта"
                onChange={()=>{}}
                mask="__.__.____"
                renderInput={params => (
                  <TextField
                    data-cy="old-aosr-date"
                    {...params}
                  />
                )}
                value={act?.actDate ?? null}
              />
              <Controller
                key="newAosrDate"
                control={control}
                errors={errors}
                name="newAosrDate"
                render={({ onChange, value, ...rest }) => (
                  <DatePicker
                    {...rest}
                    clearable
                    label="Новая дата акта"
                    mask="__.__.____"
                    maxDate={new Date()}
                    renderInput={params => (
                      <TextField
                        data-cy="new-aosr-date"
                        error={!!errors['newAosrDate']}
                        helperText={errors['newAosrDate']?.message}
                        {...params}
                      />
                    )}
                    value={value ?? null}
                    onChange={newValue => {
                      onChange(newValue)
                    }}
                  />
                )}
                rules={{
                  required: 'Должно быть заполнено',
                  validate: value =>
                    !isNaN(Date.parse(value)) || 'Некорректная дата',
                }}
              />
              <Typography color='#333333'>При сохранении акт автоматически будет отправлен на перегенерацию</Typography>
            </ModalBody>
            <ModalFooter sx={{display:'flex', flexDirection:'row-reverse'}}>
              <Button
                type={'submit'}
                onClick={handleSubmit(handleSend)}
                      disabled={newAosrDate === undefined || isUpdating}
                      sx={{
                bgcolor: '#0034AD',
                color: '#ffffff',
                px: 2,
                py: 1,
                '&:hover': { bgcolor: '#002E9C' },
                '&:disabled': { bgcolor: '#7F99D6', color: '#ffffff' },
              }}>{isUpdating?'Обновление':'Сохранить'}</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </form>
    </>

  )
}

export default ChangeAosrDate
